import React from 'react';

const IconLogo = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="1500.000000pt"
    height="1500.000000pt"
    viewBox="0 0 1500.000000 1500.000000"
    preserveAspectRatio="xMidYMid meet">
    <g
      transform="translate(0.000000,1500.000000) scale(0.100000,-0.100000)"
      fill="#D86032"
      stroke="#0000000"
      strokeWidth="1px"
      strokeDasharray="2,2">
      <path
        d="M3520 7639 l0 -4051 23 14 c43 29 325 188 343 195 11 3 37 19 59 33
  22 15 150 94 285 175 135 82 254 154 265 162 11 7 175 116 365 242 190 126
  422 284 515 352 167 121 517 384 539 404 6 6 81 66 166 135 85 68 197 160 249
  205 358 305 307 261 546 475 223 200 276 251 650 625 354 353 409 411 579 605
  61 69 117 132 125 141 18 18 24 25 191 219 68 80 127 147 130 150 3 3 21 25
  40 50 19 25 37 47 40 50 11 9 244 303 326 409 43 57 97 127 120 155 51 64 309
  421 394 544 36 52 90 129 120 173 30 43 95 141 144 217 49 75 110 169 136 207
  25 39 70 110 100 158 30 49 72 116 94 150 45 73 239 408 318 552 31 55 70 125
  87 155 17 30 55 100 83 155 29 55 58 109 65 120 16 27 267 533 329 665 27 58
  61 128 75 157 l26 52 -3764 1 -3763 0 0 -4051z m1805 2564 c76 -148 236 -456
  356 -686 l216 -418 170 323 c93 178 255 486 359 685 l189 362 253 1 252 0 -2
  -1212 -3 -1211 -233 -1 -232 -1 -1 793 -1 794 -37 -69 c-21 -37 -160 -303
  -310 -590 l-273 -523 -128 0 -128 0 -268 513 c-147 281 -287 548 -311 592
  l-42 80 2 -792 c1 -522 -1 -794 -8 -796 -5 -2 -111 -3 -233 -2 -154 1 -226 5
  -231 13 -4 6 -6 24 -5 39 1 15 3 556 3 1201 l1 1172 253 0 253 0 139 -267z"
      />
      <path
        d="M8640 6616 c0 -144 4 -207 12 -212 6 -4 181 -8 389 -8 207 -1 380 -4
  384 -8 4 -3 7 -465 8 -1025 l2 -1018 243 -3 242 -2 0 1024 c0 981 1 1024 18
  1028 10 3 180 5 378 6 197 0 367 0 377 1 16 1 17 18 17 211 l0 210 -1035 0
  -1034 0 -1 -204z"
      />
    </g>
  </svg>
);

export default IconLogo;
